
import './assets/style/reset.css'
import './assets/style/common.css'
import './assets/iconfont/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css'

import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import Router from 'vue-router'

import Axios from 'axios'
import qs from "qs";

import router from './router'
import store from './store'
import echarts from 'echarts'

// import '../node_modules/echarts/map/js/world.js' // 引入世界地图

Axios.defaults.baseURL = process.env.NODE_ENV === "production"?'':'/api';
Vue.config.productionTip = false
Vue.prototype.$axios = Axios;
Vue.prototype.$qs = qs;
Vue.prototype.urlCommon = '';
Vue.prototype.logoutHref = '/logout';


const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.prototype.$echarts = echarts
// Vue.prototype.baseUrl = 'http://192.168.2.190:999';
Vue.prototype.baseUrl = '';
// Vue.prototype.baseUrl = 'http://192.168.2.62:997';

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')





import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),
		meta:{
			title: '登录',
		}
  },
	{
		path: '/register',
		name: 'Register',
		component: () => import(/* webpackChunkName: "about" */ '../views/register.vue'),
		meta:{
			title: '注册'
		}
	},
	{
		path: '/welcome',
		name: 'Welcome',
		component: () => import(/* webpackChunkName: "about" */ '../views/welcome.vue'),
		meta:{
			title: '欢迎页'
		}
	},
	{
		path: '/welcomeBasic',
		name: 'WelcomeBasic',
		component: () => import(/* webpackChunkName: "about" */ '../views/welcomeBasic.vue'),
		meta:{
			title: '基础版欢迎页'
		}
	},
	{
		path: '/basic/basicEvalGuide',
		name: 'BasicEvalGuide',
		component: () => import(/* webpackChunkName: "about" */ '../views/basic/testBasic.vue'),
		meta:{
			title: '基础版-测评向导页'
		}
	},
	{
		path: '/basic/basicEvalResult',
		name: 'BasicEvalResult',
		component: () => import(/* webpackChunkName: "about" */ '../views/basic/basicEvalResult.vue'),
		meta:{
			title: '基础版-测评结果页'
		}
	},
	{
		path: '/welcomeSpecial',
		name: 'WelcomeSpecial',
		component: () => import(/* webpackChunkName: "about" */ '../views/welcomeSpecial.vue'),
		meta:{
			title: '专业版欢迎页'
		}
	},
	{
		path: '/special/specialEvalGuide',
		name: 'SpecialEvalGuide',
		component: () => import(/* webpackChunkName: "about" */ '../views/special/testEvalGuide.vue'),
		meta:{
			title: '专业版-测评向导页'
		}
	},
	{
		path: '/special/specialEvalResult',
		name: 'SpecialEvalResult',
		component: () => import(/* webpackChunkName: "about" */ '../views/special/specialEvalResult.vue'),
		meta:{
			title: '专业版-测评结果页'
		}
	},
  {
		path:'/err',
		name:'loginError',
		component:()=>import('../views/loginError.vue'),
		meta:{
			title:'认证失败页'
		}
  },
  {
	path:'/err/:code',
		name:'loginError',
		component:()=>import('../views/loginError.vue'),
		meta:{
			title:'认证失败页'
		}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // document.title = to.meta.title
	document.title = '中国科协企业创新服务中心'
  next()
});
export default router
